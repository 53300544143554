import styled from "styled-components"

const StyledContents = styled.div`
  text-align: center;
  p {
    text-align: center;
    margin: auto;
    font-size: 14px;
    line-height: 18px;
    opacity: 0.8;
  }
  @media (max-width: 600px) {
    text-align: left;
    padding: 0 2rem;
    p {
      text-align: left;
    }
  }
`

export default StyledContents

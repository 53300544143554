import styled from "styled-components"
import { Link } from "gatsby"

const StyledGatsbyLink = styled(Link)`
  width: min-content;
  white-space: nowrap;
  min-height: 45px;
  max-width: ${props => props.maxWidth};
  border: none;
  border-radius: 4px;
  color: ${props => props.theme.beige()};
  border-color: ${props => props.theme.beige()};
  border-width: 2px;
  border-style: solid;
  background: transparent;
  transition: 0.9s all;
  cursor: pointer;
  letter-spacing: 4px;
  font-size: 14;
  font-weight: 600;
  padding: 6px 10px;
  outline: none;
  text-transform: uppercase;
  &:hover {
    border-color: transparent;
    background-color: ${props => props.theme.black(0.7)};
  }
`

export default StyledGatsbyLink

import React from "react"
import styled from "styled-components"

import Logo from "./Logo"
import Chocolate from "./images/Chocolate"
import Peanuts from "./images/Peanuts"
import Barrels from "./images/Barrels"
import WhiskeyGlasses from "./images/WhiskeyGlasses"
import MapSection from "./MapSection"
import ContactForm from "./ContactForm"
import AboutSection from "./AboutSection"
import BuySection from "./BuySection"

const QuadrantsLayout = styled.div`
  overflow: hidden;
  /* display: flex;
  align-items: center;
  justify-content: center; */

  .quadrants {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 50vh 50vh;
    .quadrant {
      height: 50vh;
      width: 50vw;
      img {
        height: 100%;
      }
    }
  }
  .middleCenter {
    opacity: 1;
    position: absolute;
    top: calc(50vh - 100px);
    left: calc(50vw - 100px);
  }
  ::before {
    content: "";
    background-color: black;
    position: absolute;
    height: 100%;
    width: 100%;
  }
  @media (max-width: 600px) {
    height: 800vh;
    .quadrants {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr 1fr;
      .quadrant {
        height: 200vh;
        width: 100vw;
      }
    }
  }
`

const translateMap = {
  top: `translateY(-50vh)`,
  bottom: `translateY(50vh)`,
  right: `translateX(50vw)`,
  left: `translateX(-50vw)`,
}

const StyledQuadrant = styled.div`
  position: relative;
  background-color: black;
  .background {
    opacity: 0.3;
    height: 100%;
  }
  .overlay {
    position: absolute;
    top: 0;
    background-color: ${props => props.theme.brown(0.85)};
    height: 50vh;
    width: 50vw;
    transform: ${props => translateMap[props.from]};
    display: flex;
    align-items: center;
    justify-content: center;
  }
  :hover {
    .overlay {
      transform: translateY(0);
      transition: all 0.2s;
    }
  }
  @media (max-width: 600px) {
    height: 100vh;
    width: 100vw;
    position: static;
    .overlay {
      height: 100vh;
      width: 100vw;
      position: relative;
      transform: none;
      background-color: ${props => props.theme.brown(0.95)};
    }
  }
`

export default function HomePage() {
  return (
    <QuadrantsLayout>
      <div className="quadrants">
        <TopLeft className="quadrant" />
        <TopRight className="quadrant" />
        <BottomLeft className="quadrant" />
        <BottomRight className="quadrant" />
      </div>
      <div className="middleCenter">
        <Logo />
      </div>
    </QuadrantsLayout>
  )
}

const TopLeft = () => {
  return (
    <StyledQuadrant from="top">
      <div className="background">
        <Chocolate />
      </div>
      <div className="overlay">
        <AboutSection />
      </div>
    </StyledQuadrant>
  )
}

const TopRight = () => {
  return (
    <StyledQuadrant from="right">
      <div className="background">
        <Barrels />
      </div>
      <div className="overlay">
        <MapSection />
      </div>
    </StyledQuadrant>
  )
}

const BottomLeft = () => {
  return (
    <StyledQuadrant from="left">
      <div className="background">
        <WhiskeyGlasses />
      </div>
      <div className="overlay">
        <BuySection />
      </div>
    </StyledQuadrant>
  )
}

const BottomRight = () => {
  return (
    <StyledQuadrant from="bottom">
      <div className="background">
        <Peanuts />
      </div>
      <div className="overlay">
        <ContactForm />
      </div>
    </StyledQuadrant>
  )
}

import styled from "styled-components"

const StyledOverImageContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.brown(0.85)};
  position: absolute;
  top: 0;
  left: 0;
  @media (max-width: 600px) {
    padding: 0 2rem;
  }
`

export default StyledOverImageContent

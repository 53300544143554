import React from "react"

import StyledContents from "./styled/StyledContents"
import StyledAbsoluteImageWrapper from "./styled/StyledAbsoluteImageWrapper"
import StyledOverImageContent from "./styled/StyledOverImageContent"
import Map from "./images/Map"
import StyledGatsbyLink from "./styled/StyledGatsbyLink"

const MapSection = () => {
  return (
    <StyledContents>
      <StyledAbsoluteImageWrapper>
        <Map />
      </StyledAbsoluteImageWrapper>
      <StyledOverImageContent>
        <div>
          <h2 style={{ margin: "3rem auto" }}>Find a Bottle Near You</h2>
          <StyledGatsbyLink to="/map">Where to Buy</StyledGatsbyLink>
        </div>
      </StyledOverImageContent>
    </StyledContents>
  )
}

export default MapSection

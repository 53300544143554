import React from "react"

import StyledContents from "./styled/StyledContents"

const AboutSection = () => {
  return (
    <StyledContents>
      <h1>PB Chocolati</h1>
      <h3>Chocolate and Peanut Butter Whiskey</h3>
      <p>
        PB Chocolati is an incredibly smooth flavored whiskey that is made for
        easy drinking on its own. Every sip carries the whiskey flavor you love,
        layered between rich notes of chocolate and peanut butter. This whiskey
        is bold, and silky smooth, with a hint of pleasant chocolate sweetness,
        and is the perfect whiskey for chocolate and peanut butter lovers
        everywhere.
      </p>
    </StyledContents>
  )
}

export default AboutSection

import React from "react"
import styled from "styled-components"

import StyledContents from "./styled/StyledContents"
import StyledButtonLink from "./styled/StyledButtonLink"

const StyledBuyContainer = styled.div`
  h1 {
    margin-bottom: 10px;
  }
  .buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 1.6rem auto;
    flex-direction: column;
    height: 100px;
    a {
      width: 350px;
    }
  }
`

const BuySection = () => {
  return (
    <StyledContents>
      <StyledBuyContainer>
        <h1>Get PB Chocolati Delivered</h1>
        <div className="buttons">
          <StyledButtonLink
            href="https://shop.pbchocolati.com/"
            rel="noopener noreferrer"
            target="_blank"
          >
            PB Chocolati Store
          </StyledButtonLink>
        </div>
      </StyledBuyContainer>
    </StyledContents>
  )
}

export default BuySection
